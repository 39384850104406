import React, { useRef } from 'react'
import { format } from 'date-fns'
import { useReactToPrint } from 'react-to-print'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPrint, faDownload } from '@fortawesome/free-solid-svg-icons'

interface Payment {
  id: string
  amount: number
  paymentMethod: string
  transactionId: string
  status: string
  createdAt: string
}

interface Order {
  id: string
  paperType: string
  instructions: string
  numberOfPages: number
  dueDate: string
  depositAmount: number
  totalAmount: number
  status: string
  uploadedFiles?: {
    id: string
    url: string
    name: string
    size: number
    mimeType: string
  }[]
}

interface ReceiptProps {
  payment: Payment
  order: Order
  onClose: () => void
}

const Receipt: React.FC<ReceiptProps> = ({ payment, order, onClose }) => {
  const receiptRef = useRef<HTMLDivElement>(null)

  const handlePrint = useReactToPrint({
    contentRef: receiptRef,
    documentTitle: `Receipt-${payment.id}`,
    onAfterPrint: () => {
      console.log('Print completed')
    },
  })

  const handleDownloadPDF = () => {
    handlePrint()
  }

  return (
    <div className="fixed inset-0 bg-secondary bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-on-primary rounded-lg shadow-lg w-full max-w-2xl p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">Payment Receipt</h2>
          <div className="flex space-x-2">
            <button
              onClick={handleDownloadPDF}
              className="px-4 py-2 bg-primary text-on-primary rounded flex items-center"
            >
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Download
            </button>
            <button
              onClick={() => handlePrint()}
              className="px-4 py-2 bg-primary text-on-primary rounded flex items-center"
            >
              <FontAwesomeIcon icon={faPrint} className="mr-2" />
              Print
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-error text-on-primary rounded"
            >
              Close
            </button>
          </div>
        </div>

        <div ref={receiptRef} className="p-6 border border-gray-200 rounded-lg">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-2xl font-bold text-primary">AnyDay</h1>
              <p className="text-gray-600">Academic Writing Services</p>
            </div>
            <div className="text-right">
              <h2 className="text-xl font-semibold">Receipt</h2>
              <p className="text-gray-600">#{payment.id.substring(0, 8)}</p>
            </div>
          </div>

          <div className="border-t border-b border-gray-200 py-4 my-4">
            <div className="flex justify-between mb-2">
              <span className="font-semibold">Date:</span>
              <span>
                {format(new Date(payment.createdAt), 'MMMM dd, yyyy')}
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-semibold">Transaction ID:</span>
              <span>{payment.transactionId || 'N/A'}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-semibold">Payment Method:</span>
              <span>{payment.paymentMethod || 'Stripe'}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="font-semibold">Status:</span>
              <span>{payment.status}</span>
            </div>
          </div>

          <div className="mb-6">
            <h3 className="font-semibold mb-2">Order Details</h3>
            <div className="flex justify-between mb-2">
              <span>Order ID:</span>
              <span>{order.id.substring(0, 8)}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Paper Type:</span>
              <span>{order.paperType}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Number of Pages:</span>
              <span>{order.numberOfPages}</span>
            </div>
            <div className="flex justify-between mb-2">
              <span>Due Date:</span>
              <span>{format(new Date(order.dueDate), 'MMMM dd, yyyy')}</span>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-4">
            <div className="flex justify-between items-center text-lg font-semibold">
              <span>Amount Paid:</span>
              <span>${payment.amount.toFixed(2)}</span>
            </div>
            {payment.amount < order.totalAmount && (
              <div className="flex justify-between items-center text-sm text-gray-600 mt-1">
                <span>Total Order Amount:</span>
                <span>${order.totalAmount.toFixed(2)}</span>
              </div>
            )}
          </div>

          <div className="mt-8 text-center text-gray-500 text-sm">
            <p>Thank you for your business!</p>
            <p>If you have any questions, please contact support@anyday.com</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Receipt)
