import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Select, {
  StylesConfig,
  SingleValue,
  components,
  DropdownIndicatorProps,
} from 'react-select'
import { useMutation, gql, ApolloError } from '@apollo/client'
import './Order.css'
import Icon from '@mdi/react'
import { mdiPlus, mdiMinus, mdiChevronDown } from '@mdi/js'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

// Define the type for paper options
interface PaperOption {
  value: string
  label: string
}

const REGISTER_AND_CREATE_ORDER_MUTATION = gql`
  mutation registerAndCreateOrder($input: RegisterAndCreateOrderInput!) {
    registerAndCreateOrder(input: $input) {
      success
      message
      verificationToken
    }
  }
`

// Define the type for the select's value
type PaperType = PaperOption | null

// Memoize paper options to prevent unnecessary re-renders
const paperOptions: PaperOption[] = [
  { value: 'ESSAY', label: 'Essay (any type)' },
  { value: 'ADMISSION_ESSAY', label: 'Admission essay' },
  { value: 'ANNOTATED_BIBLIOGRAPHY', label: 'Annotated bibliography' },
  { value: 'ARGUMENTATIVE_ESSAY', label: 'Argumentative essay' },
  { value: 'ARTICLE_REVIEW', label: 'Article review' },
  { value: 'BOOK_MOVIE_REVIEW', label: 'Book/movie review' },
  { value: 'BUSINESS_PLAN', label: 'Business plan' },
  { value: 'PRESENTATION_SPEECH', label: 'Presentation speech' },
  { value: 'RESEARCH_PROPOSAL', label: 'Research proposal' },
  { value: 'CASE_STUDY', label: 'Case study' },
  { value: 'CRITICAL_THINKING', label: 'Critical thinking' },
  { value: 'COURSE_WORK', label: 'Course work' },
  { value: 'TERM_PAPER', label: 'Term paper' },
  {
    value: 'THESIS_DISSERTATION_CHAPTER',
    label: 'Thesis/Dissertation chapter',
  },
  { value: 'CREATIVE_WRITING', label: 'Creative writing' },
  { value: 'OTHER', label: 'Other' },
]

const OrderNow = () => {
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [warning, setWarning] = useState('')
  const [words, setWords] = useState(275)
  const [selectedPaperType, setSelectedPaperType] = useState<PaperType>(null)
  const [email, setEmail] = useState('')
  const [dueDate, setDueDate] = useState('')

  // Use the useMutation hook to handle the registerAndCreateOrder mutation
  const [registerAndCreateOrder, { data, loading, error }] = useMutation(
    REGISTER_AND_CREATE_ORDER_MUTATION,
  )

  useEffect(() => {
    if (numberOfPages < 1) {
      const warningMessage = 'Number of pages cannot be less than one :('
      setWarning(warningMessage)
      toast.warning(warningMessage, { position: 'top-right' })
      setTimeout(() => {
        setWarning('')
        setNumberOfPages(1)
      }, 3000)
    } else {
      setWarning('')
      setWords(numberOfPages * 275)
    }
  }, [numberOfPages])

  const handlePlus = useCallback(() => {
    setNumberOfPages((prevNumberOfPages) => prevNumberOfPages + 1)
  }, [])

  const handleMinus = useCallback(() => {
    if (numberOfPages > 1) {
      setNumberOfPages((prevNumberOfPages) => prevNumberOfPages - 1)
    }
  }, [numberOfPages])

  const handleSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()

      try {
        const { data } = await registerAndCreateOrder({
          variables: {
            input: {
              email,
              paperType: selectedPaperType ? selectedPaperType.value : '',
              numberOfPages: numberOfPages,
              dueDate,
            },
          },
        })
        console.log('Order created successfully', data)
        toast.success(
          'Confirmation Email has been sent successfully! Please check your inbox to continue.',
          { position: 'top-right' },
        )
      } catch (error) {
        const apolloError = error as ApolloError
        console.error('Unexpected error:', apolloError.message)
        toast.error('Failed to create order. Please try again later.', {
          position: 'top-right',
        })
      }
    },
    [email, selectedPaperType, numberOfPages, dueDate, registerAndCreateOrder],
  )

  // Custom Dropdown Indicator
  const CustomDropdownIndicator = React.memo(
    (props: DropdownIndicatorProps<PaperOption>) => {
      return (
        <components.DropdownIndicator {...props}>
          <Icon path={mdiChevronDown} size={1} style={{ color: 'black' }} />
        </components.DropdownIndicator>
      )
    },
  )

  // Add display name to fix ESLint error
  CustomDropdownIndicator.displayName = 'CustomDropdownIndicator'

  // Custom styles for react-select - memoized to prevent unnecessary recalculations
  const customStyles = useMemo<StylesConfig<PaperOption, false>>(
    () => ({
      control: (provided, state) => ({
        ...provided,
        background: 'transparent',
        display: 'flex',
        flexWrap: 'nowrap',
        // borderColor: 'hsl(0deg 78.56% 55.56%);',
        borderColor: 'transparent',
        '&:hover': { borderColor: state.isFocused ? 'gray' : 'gray' },
        boxShadow: state.isFocused ? '0 0 0 0px var(--focus-color)' : 'none',
        border: '0.5px solid black',
        width: 'full',
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: 'black', // Change the color of the separator
        width: '0.5px', // Adjust the width of the separator
        margin: '0 8px', // Adjust the margin (spacing) around the separator
      }),
      menu: (provided, state) => ({
        ...provided,
        // backgroundColor: 'var(--input-background)',
        backgroundColor: 'transparent',
        // color: 'var(--text-color)',
        color: 'transparent',
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
          ? 'var(--selected-background)'
          : state.isFocused
            ? 'var(--focus-background)'
            : 'var(--input-background)',
        color: 'var(--text-color)',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: 'var(--text-color)',
      }),
    }),
    [],
  )

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value)
    },
    [],
  )

  const handleDueDateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDueDate(e.target.value)
    },
    [],
  )

  const handlePaperTypeChange = useCallback(
    (selectedOption: SingleValue<PaperOption>) => {
      setSelectedPaperType(selectedOption)
    },
    [],
  )

  return (
    <div className="w-96">
      <form
        onSubmit={handleSubmit}
        className="bg-on-primary shadow-md rounded px-8 pt-6 mb-4 text-1xl font-semibold text-on-background"
      >
        Unlock better papers
        <div>
          <label className="pb-2 text-start block text-on-background text-sm font-light">
            Email
          </label>
          <input
            className="required:border-error invalid:border-error shadow border-0 focus:border-1 rounded w-full py-2 px-3 focus:outline-none focus:shadow-outline text-secondary"
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="my-3">
          <label className="pb-2 text-start block text-on-background text-sm font-light">
            Type of paper
          </label>
          <div className="flex justify-start">
            <div className="w-full shadow border-0 focus:border-1 py-2 px-3">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select paper type"
                isClearable={true}
                isSearchable={true}
                name="paperType"
                options={paperOptions}
                styles={customStyles}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                onChange={handlePaperTypeChange}
                value={selectedPaperType}
              />
            </div>
          </div>
        </div>
        <label className="pb-2 text-start block text-on-background text-sm font-light">
          Pages
        </label>
        <div className="flex justify-start items-center">
          <button
            type="button"
            className="rounded shadow border-0 hover:text-on-primary bg-primary-container px-6 py-2"
            onClick={handleMinus}
          >
            <Icon path={mdiMinus} title="" size={1} />
          </button>
          <input
            type="number"
            className="opacity-100 text-secondary w-20 h-10 border-0 hover:border-0 text-center focus:outline-none"
            value={numberOfPages}
            readOnly
          />
          <button
            type="button"
            className="rounded shadow border-0 hover:text-on-primary bg-primary-container px-6 py-2"
            onClick={handlePlus}
          >
            <Icon path={mdiPlus} title="" size={1} />
          </button>
          <div className="ml-5 text-secondary bg-primary-container rounded px-1 py-1 text-sm font-semibold text-center">
            {words} words
          </div>
        </div>
        <label className="pb-2 pt-3 text-start block text-on-background text-sm font-light">
          Deadline
        </label>
        <div className="flex justify-start items-center text-secondary gap-[5px]">
          <FontAwesomeIcon
            icon={faCalendar as IconProp}
            size="xl"
            className="lg:hidden"
          />
          <input
            className="required:border-error invalid:border-error shadow border-0 focus:border-1 rounded w-30 py-2 px-3 focus:outline-none focus:shadow-outline text-secondary date-pointer"
            type="datetime-local"
            placeholder="Date"
            value={dueDate}
            onChange={handleDueDateChange}
            required
          />
        </div>
        <div className="flex justify-center pt-4">
          <div className="bg-warning text-xs font-md text-secondary text-on-primary rounded w-full py-2 border-1 outline-5 border-primary px-3 text-center">
            We recommend leaving at least <span className="font-bold">1</span>{' '}
            hour to complete the order.
          </div>
        </div>
        <div className="flex justify-center items-center h-20">
          <button
            type="submit"
            className="border-black border-2 px-11 py-1 rounded-full font-semibold text-black hover:bg-primary hover:text-on-primary active:bg-tertiary-container"
          >
            {loading ? 'Working on it...' : 'Place your order'}
          </button>
        </div>
        <div className="font-light text-on-background text-xs py-3 pb-6">
          This site is protected by reCAPTCHA and the google{' '}
          <a
            className="text-primary hover:text-on-primary-container hover:font-semibold"
            href="#"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href="#"
            className="text-primary hover:text-on-primary-container hover:font-semibold"
          >
            Terms of Service apply
          </a>
        </div>
      </form>
    </div>
  )
}

export default React.memo(OrderNow)
