import React, { useState } from 'react'
import { useQuery, gql } from '@apollo/client'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faDownload,
  faSpinner,
  faFilter,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import Receipt from '../../components/Payment/Receipt'

const GET_USER_PAYMENTS = gql`
  query GetUserPayments {
    payments {
      id
      amount
      paymentMethod
      transactionId
      status
      createdAt
      order {
        id
        paperType
        instructions
        numberOfPages
        dueDate
        depositAmount
        totalAmount
        status
      }
    }
  }
`

interface Payment {
  id: string
  amount: number
  paymentMethod: string
  transactionId: string
  status: string
  createdAt: string
  order: {
    id: string
    paperType: string
    instructions: string
    numberOfPages: number
    dueDate: string
    depositAmount: number
    totalAmount: number
    status: string
    uploadedFiles?: {
      id: string
      url: string
      name: string
      size: number
      mimeType: string
    }[]
  }
}

const PaymentDashboard: React.FC = () => {
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null)
  const [searchTerm, setSearchTerm] = useState('')
  const [statusFilter, setStatusFilter] = useState('ALL')
  const [dateFilter, setDateFilter] = useState('ALL')

  const { loading, error, data, refetch } = useQuery(GET_USER_PAYMENTS, {
    fetchPolicy: 'network-only',
  })

  const handleGenerateReceipt = (payment: Payment) => {
    setSelectedPayment(payment)
  }

  const closeReceiptModal = () => {
    setSelectedPayment(null)
  }

  const getDateFilterRange = () => {
    const now = new Date()
    switch (dateFilter) {
      case 'LAST_7_DAYS': {
        const sevenDaysAgo = new Date()
        sevenDaysAgo.setDate(now.getDate() - 7)
        return sevenDaysAgo
      }
      case 'LAST_30_DAYS': {
        const thirtyDaysAgo = new Date()
        thirtyDaysAgo.setDate(now.getDate() - 30)
        return thirtyDaysAgo
      }
      case 'LAST_90_DAYS': {
        const ninetyDaysAgo = new Date()
        ninetyDaysAgo.setDate(now.getDate() - 90)
        return ninetyDaysAgo
      }
      default:
        return null
    }
  }

  const filteredPayments = () => {
    if (!data?.payments) return []

    let filtered = [...data.payments]

    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase()
      filtered = filtered.filter(
        (payment) =>
          payment.order.paperType.toLowerCase().includes(term) ||
          payment.transactionId?.toLowerCase().includes(term) ||
          payment.id.toLowerCase().includes(term),
      )
    }

    // Apply status filter
    if (statusFilter !== 'ALL') {
      filtered = filtered.filter((payment) => payment.status === statusFilter)
    }

    // Apply date filter
    const dateRange = getDateFilterRange()
    if (dateRange) {
      filtered = filtered.filter(
        (payment) => new Date(payment.createdAt) >= dateRange,
      )
    }

    return filtered
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8 h-screen">
        <FontAwesomeIcon
          icon={faSpinner}
          spin
          className="text-primary mr-2 text-2xl"
        />
        <span className="text-xl">Loading payment history...</span>
      </div>
    )
  }

  if (error) {
    return (
      <div className="text-error p-8 text-center">
        <p className="text-xl mb-4">Error loading payment history</p>
        <p>{error.message}</p>
        <button
          onClick={() => refetch()}
          className="mt-4 px-4 py-2 bg-primary text-on-primary rounded"
        >
          Try Again
        </button>
      </div>
    )
  }

  const payments = filteredPayments()

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Payment Dashboard</h1>

      {/* Filters and Search */}
      <div className="bg-on-primary rounded-lg shadow p-4 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search by order title or transaction ID"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded w-full"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="flex items-center">
            <FontAwesomeIcon icon={faFilter} className="text-gray-400 mr-2" />
            <select
              className="border border-gray-300 rounded p-2 w-full"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="ALL">All Statuses</option>
              <option value="COMPLETED">Completed</option>
              <option value="PENDING">Pending</option>
              <option value="FAILED">Failed</option>
            </select>
          </div>

          <div className="flex items-center">
            <FontAwesomeIcon icon={faFilter} className="text-gray-400 mr-2" />
            <select
              className="border border-gray-300 rounded p-2 w-full"
              value={dateFilter}
              onChange={(e) => setDateFilter(e.target.value)}
            >
              <option value="ALL">All Time</option>
              <option value="LAST_7_DAYS">Last 7 Days</option>
              <option value="LAST_30_DAYS">Last 30 Days</option>
              <option value="LAST_90_DAYS">Last 90 Days</option>
            </select>
          </div>

          <button
            onClick={() => {
              setSearchTerm('')
              setStatusFilter('ALL')
              setDateFilter('ALL')
            }}
            className="bg-secondary text-on-secondary px-4 py-2 rounded"
          >
            Clear Filters
          </button>
        </div>
      </div>

      {/* Payment Table */}
      {payments.length === 0 ? (
        <div className="bg-on-primary rounded-lg shadow p-8 text-center">
          <p className="text-xl text-gray-500">No payment records found.</p>
          <p className="mt-2 text-gray-400">
            {searchTerm || statusFilter !== 'ALL' || dateFilter !== 'ALL'
              ? 'Try adjusting your filters to see more results.'
              : 'Once you make payments, they will appear here.'}
          </p>
        </div>
      ) : (
        <div className="bg-on-primary rounded-lg shadow overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead className="bg-primary text-on-primary">
                <tr>
                  <th className="py-3 px-4 text-left">Date</th>
                  <th className="py-3 px-4 text-left">Order</th>
                  <th className="py-3 px-4 text-left">Amount</th>
                  <th className="py-3 px-4 text-left">Method</th>
                  <th className="py-3 px-4 text-left">Status</th>
                  <th className="py-3 px-4 text-left">Receipt</th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment: Payment) => (
                  <tr
                    key={payment.id}
                    className="border-b border-gray-200 hover:bg-gray-50"
                  >
                    <td className="py-3 px-4">
                      {format(new Date(payment.createdAt), 'MMM dd, yyyy')}
                    </td>
                    <td className="py-3 px-4">
                      <div className="font-medium">
                        {payment.order.paperType}
                      </div>
                      <div className="text-xs text-gray-500">
                        Order #{payment.order.id.substring(0, 8)}
                      </div>
                    </td>
                    <td className="py-3 px-4">${payment.amount.toFixed(2)}</td>
                    <td className="py-3 px-4">
                      {payment.paymentMethod || 'Stripe'}
                    </td>
                    <td className="py-3 px-4">
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${
                          payment.status === 'COMPLETED'
                            ? 'bg-green-100 text-green-800'
                            : payment.status === 'PENDING'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {payment.status}
                      </span>
                    </td>
                    <td className="py-3 px-4">
                      <button
                        onClick={() => handleGenerateReceipt(payment)}
                        className="text-primary hover:text-primary-dark"
                        title="Download Receipt"
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Receipt Modal */}
      {selectedPayment && (
        <Receipt
          payment={selectedPayment}
          order={selectedPayment.order}
          onClose={closeReceiptModal}
        />
      )}
    </div>
  )
}

export default React.memo(PaymentDashboard)
