import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHourglassEnd,
  faSpinner,
  faCircleCheck,
  faUserCheck,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface OrderWorkflowProps {
  currentStatus: string
}

const OrderWorkflow: React.FC<OrderWorkflowProps> = ({ currentStatus }) => {
  const statuses = [
    { id: 'PENDING', label: 'Pending', icon: faHourglassEnd },
    { id: 'IN_PROGRESS', label: 'In Progress', icon: faSpinner },
    { id: 'COMPLETED', label: 'Completed', icon: faCircleCheck },
    { id: 'REVIEWED', label: 'Reviewed', icon: faUserCheck },
  ]

  // Find the index of the current status
  const currentIndex = statuses.findIndex(
    (status) => status.id === currentStatus,
  )

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold text-info mb-4">Order Status</h3>
      <div className="flex items-center justify-between">
        {statuses.map((status, index) => {
          const isActive = index <= currentIndex
          const isCurrent = status.id === currentStatus

          return (
            <React.Fragment key={status.id}>
              <div
                className={`flex flex-col items-center ${isCurrent ? 'scale-110 transition-transform duration-200' : ''}`}
              >
                <div
                  className={`w-12 h-12 rounded-full flex items-center justify-center 
                    ${isActive ? 'bg-primary text-on-primary' : 'bg-gray-200 text-gray-500'} 
                    ${isCurrent ? 'ring-4 ring-primary-light' : ''}`}
                >
                  <FontAwesomeIcon
                    icon={status.icon as IconProp}
                    className={`${isCurrent ? 'animate-pulse' : ''}`}
                  />
                </div>
                <span
                  className={`mt-2 text-sm ${isActive ? 'font-semibold text-primary' : 'text-gray-500'}`}
                >
                  {status.label}
                </span>
                {isCurrent && (
                  <span className="mt-1 text-xs text-primary">Current</span>
                )}
              </div>

              {index < statuses.length - 1 && (
                <div
                  className={`flex-1 h-1 mx-2 ${index < currentIndex ? 'bg-primary' : 'bg-gray-200'}`}
                >
                  {index === currentIndex - 1 && (
                    <FontAwesomeIcon
                      icon={faArrowRight as IconProp}
                      className="text-primary relative -top-2"
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
      <div className="mt-4 text-sm text-gray-600">
        {currentStatus === 'PENDING' && (
          <p>
            Next step: Your order will move to &quot;In Progress&quot; once
            payment is confirmed.
          </p>
        )}
        {currentStatus === 'IN_PROGRESS' && (
          <p>
            Next step: Your order will be marked as &quot;Completed&quot; when
            the work is ready for your review.
          </p>
        )}
        {currentStatus === 'COMPLETED' && (
          <p>Next step: Please review your order and provide feedback.</p>
        )}
        {currentStatus === 'REVIEWED' && (
          <p>Your order has been completed and reviewed. Thank you!</p>
        )}
      </div>
    </div>
  )
}

export default OrderWorkflow
