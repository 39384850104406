import React, { useEffect, useState, useCallback, useMemo } from 'react'
import Helmet from 'react-helmet'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import { UserProvider } from './userContext'
import { NotificationProvider } from './contexts/NotificationContext'
import Tables from './pages/Tables/Tables'
import Settings from './pages/Settings/Settings'
import AuthPasswordReset from './pages/Authentication/AuthPasswordReset'
import Registration from './pages/Registration/Registration'
import Blog from './pages/Blog/Blog'
import Samples from './pages/Samples/Samples'
import TopWriters from './pages/TopWriters/TopWriters'
import OrderNow from './pages/Order/OrderNow'
import LatestReviews from './pages/LatestReviews/LatestReviews'
import HowItWorks from './pages/HowItWorks/HowItWorks'
import NavigationBar from './pages/Navigation/NavigationBar'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import CompleteRegistration from './pages/CompleteRegistration/CompleteRegistration'
import PrivateRoutes from './utils/PrivateRoutes'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import RedirectIfLoggedIn from './components/RedirectLoggedIn/RedirectLoggedIn'
import OrderDetailPageWrapper from './pages/DashBoard/OrderDetails/OrderDetailPageWrapper'
import { Order, Orders } from './types'
import {
  faCircleCheck,
  faHourglassEnd,
  faSpinner,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons'
import ProgressTab from './pages/DashBoard/ProgressTab/dnd/ProgressTab'
import DashboardLayout from './pages/DashBoard/DashboardLayout'
import PaymentSuccess from './pages/DashBoard/OrderDetails/PaymentSuccess'
import PaymentDashboard from './pages/Payment/PaymentDashboard'

const GET_ORDERS = gql`
  query getOrders {
    getOrders {
      id
      paperType
      instructions
      numberOfPages
      dueDate
      depositAmount
      totalAmount
      status
      uploadedFiles {
        id
        name
        url
        size
        mimeType
      }
    }
  }
`

const App = () => {
  const token = localStorage.getItem('token')

  const { loading, error, data } = useQuery<{ getOrders: Order[] }>(
    GET_ORDERS,
    {
      skip: !token,
    },
  )

  const [tasks, setTasks] = useState<Orders>({
    pending: { icon: faHourglassEnd, tasks: [] },
    inProgress: { icon: faSpinner, tasks: [] },
    completed: { icon: faCircleCheck, tasks: [] },
    reviewed: { icon: faUserCheck, tasks: [] },
  })

  useEffect(() => {
    if (data && data.getOrders) {
      const newTasks: Orders = {
        pending: { icon: faHourglassEnd, tasks: [] },
        inProgress: { icon: faSpinner, tasks: [] },
        completed: { icon: faCircleCheck, tasks: [] },
        reviewed: { icon: faUserCheck, tasks: [] },
      }

      data.getOrders.forEach((order) => {
        const task: Order = {
          id: order.id,
          paperType: order.paperType,
          instructions: order.instructions,
          numberOfPages: order.numberOfPages,
          dueDate: order.dueDate,
          totalAmount: order.totalAmount,
          depositAmount: order.depositAmount,
          status: order.status,
          uploadedFiles: order.uploadedFiles,
        }

        switch (order.status) {
          case 'PENDING':
            newTasks.pending.tasks.push(task)
            break
          case 'IN_PROGRESS':
            newTasks.inProgress.tasks.push(task)
            break
          case 'COMPLETED':
            newTasks.completed.tasks.push(task)
            break
          case 'REVIEWED':
            newTasks.reviewed.tasks.push(task)
            break
          default:
            break
        }
      })

      setTasks(newTasks)
    }
  }, [data])

  const location = useLocation()
  const hideComponent =
    location.pathname === '/complete-registration' || token !== null

  const handleUpdate = useCallback((updatedTask: Order) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks }
      const columnKey = updatedTask.status.toLowerCase() as keyof Orders
      const taskIndex = updatedTasks[columnKey].tasks.findIndex(
        (t) => t.id === updatedTask.id,
      )
      if (taskIndex > -1) updatedTasks[columnKey].tasks[taskIndex] = updatedTask
      return updatedTasks
    })
  }, [])

  const handleDelete = useCallback((id: string) => {
    setTasks((prevTasks) => {
      const updatedTasks = { ...prevTasks }
      for (const key of Object.keys(updatedTasks) as Array<keyof Orders>) {
        updatedTasks[key].tasks = updatedTasks[key].tasks.filter(
          (task) => task.id !== id,
        )
      }
      return updatedTasks
    })
  }, [])

  // Memoize flatTasks to prevent unnecessary recalculations
  const flatTasks = useMemo(
    () => Object.values(tasks).flatMap((taskGroup) => taskGroup.tasks),
    [tasks],
  )

  if (loading) return <p>Loading...</p>
  console.log('Tasks!!!:', tasks)
  console.log('Orders:', data)
  // if (error) return <p>Error: {error.message}</p>

  return (
    <UserProvider>
      <NotificationProvider>
        <div className="App">
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              Essay Writing Service - We Do Essay Writing And Formatting
            </title>
            <meta
              name="description"
              content="Professional academic writing services"
            />
          </Helmet>
          {!hideComponent && <NavigationBar />}
          <ToastContainer />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<DashboardLayout />}>
                <Route
                  path="/dashboard"
                  element={<ProgressTab tasks={tasks} />}
                />
                <Route path="/settings" element={<Settings />} />
                <Route path="tables" element={<Tables />} />
                <Route
                  path="/orders/:id"
                  element={
                    <OrderDetailPageWrapper
                      tasks={flatTasks}
                      onDelete={handleDelete}
                      onUpdate={handleUpdate}
                    />
                  }
                />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route path="/payments" element={<PaymentDashboard />} />
              </Route>
            </Route>
            <Route
              path="/"
              element={
                <RedirectIfLoggedIn>
                  <section className="bg-gradient-to-r to-primary from-secondary-container">
                    <div className="flex justify-center lg:justify-around items-center lg:py-[35px]">
                      <div className="hidden lg:block">
                        <p className="tracking-wider text-5xl pr-10">
                          Online Essay Writing Service
                        </p>
                        <p className="text-5xl tracking-wider mt-10">
                          Available 24/7
                        </p>
                        <p className="text-lg py-10">
                          We do all types of writing at the cheapest rates in
                          town.
                        </p>
                      </div>
                      <div className="mt-[3px]">
                        <OrderNow />
                      </div>
                    </div>
                  </section>
                  {/* <section className="bg-gradient-to-r to-primary from-secondary-container flex justify-around items-center p-10 h-50"> */}
                  {/* <div className='hidden'>
                    <p className="tracking-wider text-5xl pr-10">
                      Online Essay Writing Service
                    </p>
                    <p className="text-5xl tracking-wider mt-10">
                      Available 24/7
                    </p>
                    <p className="text-lg py-10">
                      We do all types of writing at the cheapest rates in town.
                    </p>
                  </div>
                  <OrderNow /> */}
                  {/* </section> */}
                </RedirectIfLoggedIn>
              }
            />
            <Route
              path="/complete-registration"
              element={<CompleteRegistration />}
            />
            <Route path="/howitworks" element={<HowItWorks />} />
            <Route path="/latestreviews" element={<LatestReviews />} />
            <Route path="/topwriters" element={<TopWriters />} />
            <Route path="/samples" element={<Samples />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/registration" element={<Registration />} />
            <Route
              path="/auth_password_reset"
              element={<AuthPasswordReset />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </NotificationProvider>
    </UserProvider>
  )
}

export default React.memo(App)
