import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHourglassEnd,
  faSpinner,
  faCircleCheck,
  faUserCheck,
  faCalendarAlt,
  faClock,
  faInfoCircle,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

// This query would need to be implemented on the backend
const GET_ORDER_HISTORY = gql`
  query GetOrderHistory($orderId: ID!) {
    orderHistory(orderId: $orderId) {
      id
      orderId
      status
      timestamp
      message
      actor
    }
  }
`

// Mock data for now - this would come from the backend
const mockOrderHistory = (orderId: string, currentStatus: string) => {
  const baseHistory = [
    {
      id: '1',
      orderId,
      status: 'PENDING',
      timestamp: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(), // 7 days ago
      message: 'Order created and awaiting payment',
      actor: 'System',
    },
  ]

  if (currentStatus === 'PENDING') return baseHistory

  baseHistory.push({
    id: '2',
    orderId,
    status: 'IN_PROGRESS',
    timestamp: new Date(Date.now() - 5 * 24 * 60 * 60 * 1000).toISOString(), // 5 days ago
    message: 'Payment received. Order is now being processed.',
    actor: 'System',
  })

  if (currentStatus === 'IN_PROGRESS') return baseHistory

  baseHistory.push({
    id: '3',
    orderId,
    status: 'COMPLETED',
    timestamp: new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).toISOString(), // 1 day ago
    message: 'Order has been completed and is ready for review.',
    actor: 'System',
  })

  if (currentStatus === 'COMPLETED') return baseHistory

  baseHistory.push({
    id: '4',
    orderId,
    status: 'REVIEWED',
    timestamp: new Date().toISOString(), // Now
    message: 'Order has been reviewed and finalized.',
    actor: 'System',
  })

  return baseHistory
}

interface OrderTimelineProps {
  orderId: string
  currentStatus: string
}

interface OrderHistoryItem {
  id: string
  orderId: string
  status: string
  timestamp: string
  message: string
  actor: string
}

const OrderTimeline: React.FC<OrderTimelineProps> = ({
  orderId,
  currentStatus,
}) => {
  const [orderHistory, setOrderHistory] = useState<OrderHistoryItem[]>([])

  // In a real implementation, you would use this query
  // const { loading, error, data } = useQuery(GET_ORDER_HISTORY, {
  //   variables: { orderId },
  //   fetchPolicy: 'network-only',
  // });

  useEffect(() => {
    // Mock implementation - in production, this would use actual data from the backend
    setOrderHistory(mockOrderHistory(orderId, currentStatus))
  }, [orderId, currentStatus])

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'PENDING':
        return faHourglassEnd
      case 'IN_PROGRESS':
        return faSpinner
      case 'COMPLETED':
        return faCircleCheck
      case 'REVIEWED':
        return faUserCheck
      default:
        return faInfoCircle
    }
  }

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'PENDING':
        return 'text-yellow-500 bg-yellow-100'
      case 'IN_PROGRESS':
        return 'text-blue-500 bg-blue-100'
      case 'COMPLETED':
        return 'text-green-500 bg-green-100'
      case 'REVIEWED':
        return 'text-purple-500 bg-purple-100'
      default:
        return 'text-gray-500 bg-gray-100'
    }
  }

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold text-info mb-4">Order Timeline</h3>
      <div className="flow-root">
        <ul className="-mb-8">
          {orderHistory.map((historyItem, index) => (
            <li key={historyItem.id}>
              <div className="relative pb-8">
                {index < orderHistory.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={`h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white ${getStatusColor(historyItem.status)}`}
                    >
                      <FontAwesomeIcon
                        icon={getStatusIcon(historyItem.status) as IconProp}
                        className="h-4 w-4"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-800 font-medium">
                        {historyItem.message}
                      </p>
                      <p className="mt-1 text-xs text-gray-500">
                        Status: {historyItem.status.replace('_', ' ')}
                      </p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <div className="flex items-center">
                        <FontAwesomeIcon
                          icon={faCalendarAlt as IconProp}
                          className="mr-1 h-3 w-3"
                        />
                        <time dateTime={historyItem.timestamp}>
                          {format(
                            new Date(historyItem.timestamp),
                            'MMM dd, yyyy',
                          )}
                        </time>
                      </div>
                      <div className="flex items-center mt-1">
                        <FontAwesomeIcon
                          icon={faClock as IconProp}
                          className="mr-1 h-3 w-3"
                        />
                        <time dateTime={historyItem.timestamp}>
                          {format(new Date(historyItem.timestamp), 'h:mm a')}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default OrderTimeline
