import React, { useState, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons'

const GET_PAYMENTS_BY_ORDER = gql`
  query GetPaymentsByOrder($orderId: ID!) {
    paymentsByOrder(orderId: $orderId) {
      id
      amount
      paymentMethod
      transactionId
      status
      createdAt
    }
  }
`

interface Payment {
  id: string
  amount: number
  paymentMethod: string
  transactionId: string
  status: string
  createdAt: string
}

interface PaymentHistoryProps {
  orderId: string
  onGenerateReceipt: (payment: Payment) => void
}

const PaymentHistory: React.FC<PaymentHistoryProps> = ({
  orderId,
  onGenerateReceipt,
}) => {
  const { loading, error, data, refetch } = useQuery(GET_PAYMENTS_BY_ORDER, {
    variables: { orderId },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    // Refetch payments when the component mounts
    refetch()
  }, [refetch])

  if (loading) {
    return (
      <div className="flex justify-center items-center p-4">
        <FontAwesomeIcon icon={faSpinner} spin className="text-primary mr-2" />
        <span>Loading payment history...</span>
      </div>
    )
  }

  if (error) {
    return (
      <div className="text-error p-4">
        Error loading payment history: {error.message}
      </div>
    )
  }

  const payments = data?.paymentsByOrder || []

  if (payments.length === 0) {
    return <div className="text-secondary p-4">No payment records found.</div>
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-on-primary rounded-lg overflow-hidden">
        <thead className="bg-primary text-on-primary">
          <tr>
            <th className="py-2 px-4 text-left">Date</th>
            <th className="py-2 px-4 text-left">Amount</th>
            <th className="py-2 px-4 text-left">Method</th>
            <th className="py-2 px-4 text-left">Status</th>
            <th className="py-2 px-4 text-left">Receipt</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment: Payment) => (
            <tr
              key={payment.id}
              className="border-b border-gray-200 hover:bg-gray-50"
            >
              <td className="py-2 px-4">
                {format(new Date(payment.createdAt), 'MMM dd, yyyy')}
              </td>
              <td className="py-2 px-4">${payment.amount.toFixed(2)}</td>
              <td className="py-2 px-4">{payment.paymentMethod || 'Stripe'}</td>
              <td className="py-2 px-4">
                <span
                  className={`px-2 py-1 rounded-full text-xs ${
                    payment.status === 'COMPLETED'
                      ? 'bg-green-100 text-green-800'
                      : payment.status === 'PENDING'
                        ? 'bg-yellow-100 text-yellow-800'
                        : 'bg-red-100 text-red-800'
                  }`}
                >
                  {payment.status}
                </span>
              </td>
              <td className="py-2 px-4">
                <button
                  onClick={() => onGenerateReceipt(payment)}
                  className="text-primary hover:text-primary-dark"
                  title="Download Receipt"
                >
                  <FontAwesomeIcon icon={faDownload} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default React.memo(PaymentHistory)
