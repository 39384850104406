import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faCheckCircle,
  faExclamationCircle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Notification {
  id: string
  type: 'info' | 'success' | 'warning' | 'error'
  message: string
  timestamp: Date
  read: boolean
  orderId?: string
}

interface OrderNotificationProps {
  notifications: Notification[]
  onMarkAsRead: (id: string) => void
  onClearAll: () => void
}

const OrderNotification: React.FC<OrderNotificationProps> = ({
  notifications,
  onMarkAsRead,
  onClearAll,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [animateIcon, setAnimateIcon] = useState(false)

  // Animate bell icon when new unread notifications exist
  useEffect(() => {
    if (notifications.some((notification) => !notification.read)) {
      setAnimateIcon(true)
      const timer = setTimeout(() => setAnimateIcon(false), 5000)
      return () => clearTimeout(timer)
    }
  }, [notifications])

  const getIcon = (type: string) => {
    switch (type) {
      case 'success':
        return faCheckCircle
      case 'warning':
      case 'error':
        return faExclamationCircle
      default:
        return faInfoCircle
    }
  }

  const getIconColor = (type: string) => {
    switch (type) {
      case 'success':
        return 'text-green-500'
      case 'warning':
        return 'text-yellow-500'
      case 'error':
        return 'text-red-500'
      default:
        return 'text-blue-500'
    }
  }

  const formatTime = (date: Date) => {
    const now = new Date()
    const diffMs = now.getTime() - date.getTime()
    const diffMins = Math.round(diffMs / 60000)

    if (diffMins < 1) return 'Just now'
    if (diffMins < 60) return `${diffMins} min ago`

    const diffHours = Math.floor(diffMins / 60)
    if (diffHours < 24) return `${diffHours} hr ago`

    const diffDays = Math.floor(diffHours / 24)
    return `${diffDays} day ago`
  }

  const unreadCount = notifications.filter((n) => !n.read).length

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 text-gray-600 hover:text-primary focus:outline-none focus:text-primary"
        aria-label="Notifications"
      >
        <FontAwesomeIcon
          icon={faBell as IconProp}
          className={`h-6 w-6 ${animateIcon ? 'animate-bounce' : ''}`}
        />
        {unreadCount > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
            {unreadCount}
          </span>
        )}
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-50">
          <div className="py-2 px-3 bg-primary text-on-primary flex justify-between items-center">
            <span className="font-semibold">Notifications</span>
            {notifications.length > 0 && (
              <button
                onClick={onClearAll}
                className="text-xs text-on-primary opacity-75 hover:opacity-100"
              >
                Clear All
              </button>
            )}
          </div>

          <div className="max-h-96 overflow-y-auto">
            {notifications.length === 0 ? (
              <div className="py-4 px-3 text-center text-gray-500">
                No notifications
              </div>
            ) : (
              <div>
                {notifications.map((notification) => (
                  <div
                    key={notification.id}
                    className={`py-2 px-3 border-b border-gray-100 hover:bg-gray-50 flex items-start ${notification.read ? 'opacity-75' : 'bg-blue-50'}`}
                  >
                    <div
                      className={`mr-3 mt-1 ${getIconColor(notification.type)}`}
                    >
                      <FontAwesomeIcon
                        icon={getIcon(notification.type) as IconProp}
                      />
                    </div>
                    <div className="flex-1">
                      <p className="text-sm text-gray-800">
                        {notification.message}
                      </p>
                      <p className="text-xs text-gray-500 mt-1">
                        {formatTime(notification.timestamp)}
                      </p>
                    </div>
                    {!notification.read && (
                      <button
                        onClick={() => onMarkAsRead(notification.id)}
                        className="ml-2 text-gray-400 hover:text-gray-600"
                        aria-label="Mark as read"
                      >
                        <FontAwesomeIcon icon={faTimes as IconProp} />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default OrderNotification
