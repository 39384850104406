import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileAlt,
  faStar,
  faPlus,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

// Template interface
interface OrderTemplate {
  id: string
  name: string
  paperType: string
  description: string
  instructions: string
  numberOfPages: number
  isFavorite: boolean
  category: string
}

// Mock templates data
const mockTemplates: OrderTemplate[] = [
  {
    id: '1',
    name: 'Standard Research Paper',
    paperType: 'research_paper',
    description:
      'A comprehensive research paper template with standard formatting',
    instructions:
      'Please follow APA formatting guidelines. Include an abstract, introduction, methodology, results, discussion, and conclusion sections.',
    numberOfPages: 8,
    isFavorite: true,
    category: 'Academic',
  },
  {
    id: '2',
    name: 'Literature Review',
    paperType: 'essay',
    description: 'Template for literature review papers',
    instructions:
      'Analyze and synthesize at least 10 scholarly sources on the given topic. Follow MLA formatting.',
    numberOfPages: 6,
    isFavorite: false,
    category: 'Academic',
  },
  {
    id: '3',
    name: 'Case Study Analysis',
    paperType: 'case_study',
    description: 'Business case study analysis template',
    instructions:
      'Analyze the provided business case using SWOT analysis. Include recommendations section.',
    numberOfPages: 5,
    isFavorite: true,
    category: 'Business',
  },
  {
    id: '4',
    name: 'Argumentative Essay',
    paperType: 'argumentative_essay',
    description: 'Template for argumentative essays',
    instructions:
      'Present a clear thesis statement. Include at least 3 supporting arguments and address counterarguments.',
    numberOfPages: 4,
    isFavorite: false,
    category: 'Academic',
  },
  {
    id: '5',
    name: 'Technical Report',
    paperType: 'other',
    description: 'Template for technical reports',
    instructions:
      'Include executive summary, technical details, methodology, findings, and recommendations sections.',
    numberOfPages: 10,
    isFavorite: false,
    category: 'Technical',
  },
]

interface OrderTemplatesProps {
  onSelectTemplate: (template: OrderTemplate) => void
}

const OrderTemplates: React.FC<OrderTemplatesProps> = ({
  onSelectTemplate,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [templates, setTemplates] = useState<OrderTemplate[]>(mockTemplates)

  // Get unique categories
  const categories = Array.from(
    new Set(templates.map((template) => template.category)),
  )

  // Filter templates based on search and category
  const filteredTemplates = templates.filter((template) => {
    const matchesSearch =
      searchTerm === '' ||
      template.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      template.description.toLowerCase().includes(searchTerm.toLowerCase())

    const matchesCategory =
      selectedCategory === null || template.category === selectedCategory

    return matchesSearch && matchesCategory
  })

  // Toggle favorite status
  const toggleFavorite = (id: string) => {
    setTemplates(
      templates.map((template) =>
        template.id === id
          ? { ...template, isFavorite: !template.isFavorite }
          : template,
      ),
    )
  }

  return (
    <div className="bg-on-primary shadow-sm rounded-lg p-6">
      <h3 className="text-lg font-semibold text-info mb-4">Order Templates</h3>

      {/* Search and filter */}
      <div className="mb-6">
        <div className="relative">
          <input
            type="text"
            placeholder="Search templates..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full px-4 py-2 pl-10 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
          />
          <FontAwesomeIcon
            icon={faSearch as IconProp}
            className="absolute left-3 top-3 text-gray-400"
          />
        </div>

        {/* Category filters */}
        <div className="flex flex-wrap gap-2 mt-3">
          <button
            onClick={() => setSelectedCategory(null)}
            className={`px-3 py-1 text-sm rounded-full ${
              selectedCategory === null
                ? 'bg-primary text-on-primary'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            }`}
          >
            All
          </button>
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-3 py-1 text-sm rounded-full ${
                selectedCategory === category
                  ? 'bg-primary text-on-primary'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>

      {/* Templates grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {filteredTemplates.map((template) => (
          <div
            key={template.id}
            className="border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-start">
              <div className="flex items-center">
                <FontAwesomeIcon
                  icon={faFileAlt as IconProp}
                  className="text-primary mr-2"
                />
                <h4 className="font-medium text-gray-800">{template.name}</h4>
              </div>
              <button
                onClick={() => toggleFavorite(template.id)}
                className={`${
                  template.isFavorite
                    ? 'text-yellow-500'
                    : 'text-gray-300 hover:text-yellow-500'
                }`}
                aria-label={
                  template.isFavorite
                    ? 'Remove from favorites'
                    : 'Add to favorites'
                }
              >
                <FontAwesomeIcon icon={faStar as IconProp} />
              </button>
            </div>

            <p className="text-sm text-gray-600 mt-2">{template.description}</p>

            <div className="mt-3 flex items-center text-xs text-gray-500">
              <span className="bg-gray-100 rounded-full px-2 py-1 mr-2">
                {template.paperType.replace('_', ' ')}
              </span>
              <span>{template.numberOfPages} pages</span>
            </div>

            <button
              onClick={() => onSelectTemplate(template)}
              className="mt-4 w-full py-2 bg-primary text-on-primary rounded-md hover:bg-primary-dark transition-colors flex items-center justify-center"
            >
              <FontAwesomeIcon icon={faPlus as IconProp} className="mr-2" />
              Use Template
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OrderTemplates
